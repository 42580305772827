import { useState } from 'react';
import { useSnackbar } from 'notistack';
// import axios from 'axios';
import { ERR_TOP_CENTER, SUCCESS_TOP_CENTER } from '../../../utils/snackbar-utils';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { axs } from '../../../config/axios';

const axios = axs.cert;

export default function RenewCertForm({ info, open, handleClose, setNonce }) {
  const {
    teacherId,
    teacherName,
    certInfo: {
      subject: { country, organization, organizational_unit, locality, province }
    }
  } = info;
  const [state, setState] = useState({
    teacherId,
    teacherName,
    C: country,
    ST: province,
    L: locality,
    O: organization,
    OU: organizational_unit
  });

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function hdChange(e) {
    // console.log(e.target.name, e.target.value);
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  async function hdRenewCertificate() {
    try {
      setLoading(true);
      const { teacherId, teacherName, ...profile } = state;
      const response = await axios.post('/cert/renew', { teacherId, teacherName, profile });
      enqueueSnackbar('Renew successfully!', SUCCESS_TOP_CENTER);
    } catch (error) {
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    } finally {
      setLoading(false);
      handleClose();
      setNonce(Date.now());
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: '1rem' }}>Create certificate</DialogTitle>
      <DialogContent>
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="teacherId"
                label="Teacher Id (email)"
                value={state.teacherId}
                onChange={(e) => hdChange(e)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="teacherName" label="Teacher Name" value={state.teacherName} onChange={(e) => hdChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="OU" label="Organization Unit" value={state.OU} onChange={(e) => hdChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="O" label="Organization" value={state.O} onChange={(e) => hdChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="L" label="Location" value={state.L} onChange={(e) => hdChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="ST" label="State" value={state.ST} onChange={(e) => hdChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField fullWidth name="C" label="Country" value={state.C} onChange={(e) => hdChange(e)} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => hdRenewCertificate()} disabled={loading}>
            {loading ? <CircularProgress size="1rem" /> : 'Proceed'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
