import { DataGrid } from '@mui/x-data-grid';
// import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ERR_TOP_CENTER } from '../../../utils/snackbar-utils';

export default function LogTable({ axios, path, query, contentColumnWidth = 4000 }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    // { field: 'id', headerName: 'UserId', description: 'User ID', type: 'string', flex: 1 },
    { field: 'index', headerName: 'No', description: '', type: 'number', width: 70, align: 'center', headerAlign: 'center' },
    // { field: 'time', headerName: 'Time', description: '', type: 'string', width: 200 },
    // { field: 'content', headerName: 'Content', description: '', type: 'string', flex: 1 }
    {
      field: 'content',
      headerName: 'Content',
      description: '',
      type: 'string',
      // flex: 1,
      width: contentColumnWidth,
      renderCell: (params) => <pre>{params.value}</pre>
    }
  ];

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      try {
        const url = `${path}?page=${page + 1}&pageSize=${pageSize}${query}`;
        const response = await axios.get(url);
        const { docs, docCount } = response.data;
        setRowCount(docCount);
        const newRows = docs.map((item, index) => ({
          ...item,
          index: page * pageSize + index + 1,
          id: item._id,
          content: item.log || item.message,
          time: item.date || item.timestamp
        }));
        setRows(newRows);
      } catch (error) {
        setRows([]);
        console.error(error);
        if (error.response) enqueueSnackbar(JSON.stringify(error.response), ERR_TOP_CENTER);
        else enqueueSnackbar(error, ERR_TOP_CENTER);
      }

      if (!active) return;

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, query]);

  return (
    <div style={{ height: 675, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        loading={loading}
        disableSelectionOnClick
      />
    </div>
  );
}
