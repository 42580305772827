import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

export default function ConfirmDialog({ open, hdCancel, hdProceed }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={hdCancel}>
      <DialogTitle sx={{ fontSize: '16px' }}>Confirm action</DialogTitle>
      <DialogContent>
        <Typography align="center" variant="h4">
          Are you sure?
        </Typography>
        <Box mb={2} />

        <Box mt={2} textAlign="right">
          <Button variant="contained" color="primary" onClick={hdCancel} sx={{ mr: 2 }}>
            Cancel
          </Button>

          <Button variant="contained" color="error" onClick={hdProceed}>
            Yes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
