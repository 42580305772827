import dashboard from './dashboard';
import certManager from './cert-manager';
import totp from './totp';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, other]
  // items: [dashboard, salary]
  items: [certManager, totp]
};

export default menuItems;
