import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import escapeStringRegexp from 'escape-string-regexp';

export default function SearchForm({ method, setParams }) {
  const options = ['ALL', 'GET', 'POST', 'PATCH', 'PUT', 'DELETE'].map((item) => ({ label: item }));
  const [method_, setMethod_] = useState(options.find((item) => item.label === method) || { label: null });
  const [keyword, setKeyWord] = useState('');
  const [keywords, setKeywords] = useState([]);

  const fromDelta = Number(process.env.REACT_APP_LOG_FROM_DATE_DELTA);
  const toDelta = Number(process.env.REACT_APP_LOG_TO_DATE_DELTA);
  const [dateFrom, setDateFrom] = useState(new Date().setDate(new Date().getDate() - fromDelta));
  const [dateTo, setDateTo] = useState(new Date().setDate(new Date().getDate() + toDelta));

  function onSearch() {
    const keywords_ = keywords.map((item) => escapeStringRegexp(item.label.trim()));
    setParams({ method: method_.label === 'ALL' ? null : method_.label, dateFrom, dateTo, keywords: keywords_ });
  }

  function hdDeleteKeyword(toDeleteChip) {
    setKeywords((keywords) => keywords.filter((keyword) => keyword.key !== toDeleteChip.key));
  }
  function hdKeyUp(e) {
    if (e.keyCode === 13) {
      setKeywords((keywords) => [...keywords, { key: Date.now(), label: e.target.value }]);
      setKeyWord('');
    }
  }

  return (
    <div>
      <Grid container spacing={4} alignItems="center">
        {method && (
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="choose-method"
              options={options}
              value={method_}
              onChange={(event, newValue) => {
                setMethod_(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Method" fullWidth />}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TextField
            label="Keyword"
            variant="outlined"
            value={keyword}
            onChange={(e) => setKeyWord(e.target.value)}
            onKeyUp={(e) => hdKeyUp(e)}
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DesktopDatePicker
            label="Date from"
            inputFormat="YYYY-MM-DD"
            value={dateFrom}
            onChange={(newValue) => setDateFrom(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DesktopDatePicker
            label="Date to"
            inputFormat="YYYY-MM-DD"
            value={dateTo}
            onChange={(newValue) => setDateTo(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button variant="contained" color="primary" startIcon={<SearchIcon />} onClick={() => onSearch()}>
            Search
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <Typography display="inline" sx={{ fontSize: '1rem' }}>
          Keywords:
        </Typography>
        {keywords.map((keyword) => (
          <Chip key={keyword.key} label={keyword.label} onDelete={() => hdDeleteKeyword(keyword)} sx={{ ml: 1 }} size="medium" />
        ))}
      </Box>
    </div>
  );
}
