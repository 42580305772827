// import axios from 'axios';
import { axs } from '../config/axios';

function setToken(responseBody) {
  localStorage.setItem('token', JSON.stringify(responseBody));
  axs.log.defaults.headers.common.Authorization = responseBody.log;
  axs.cert.defaults.headers.common.Authorization = responseBody.cert;
  axs.totp.defaults.headers.common.Authorization = responseBody.totp;
}

function getToken() {
  return localStorage.getItem('token');
}

export { getToken, setToken };
