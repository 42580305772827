import { DataGrid } from '@mui/x-data-grid';
// import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import { axs } from '../../../../../config/axios';
import { ERR_TOP_CENTER } from '../../../../../utils/snackbar-utils';
import { formatDate } from '../../../../../utils/formatter';

const axios = axs.log;

export default function UserStatusTable({ nonce, keyword }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    // { field: 'id', headerName: 'UserId', description: 'User ID', type: 'string', flex: 1 },
    { field: 'index', headerName: 'No', description: '', type: 'number', width: 70, align: 'center', headerAlign: 'center' },
    { field: 'id', headerName: 'Id', description: 'Id', type: 'string', flex: 1 },
    {
      field: 'active',
      headerName: 'Active',
      description: 'is active',
      type: 'string',
      width: 75,
      align: 'center',
      headerAlign: 'center'
    },
    { field: 'firstInit', headerName: 'First Init', description: '', type: 'string', flex: 1 },
    { field: 'lastReset', headerName: 'Last Reset', description: '', type: 'string', flex: 1 },
    { field: 'lastVerify', headerName: 'Last Verify', description: '', type: 'string', flex: 1 },
    {
      field: 'lastVerifyResult',
      headerName: 'Last Verify Result',
      description: '',
      type: 'string',
      width: 125,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: '_id',
      headerName: 'Details',
      description: '',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => {
        console.log();
        return (
          <Link href={`/totp/monitor-user/${params.row._id}`} target="_blank">
            <OpenInNewIcon />
          </Link>
        );
      }
    }
  ];

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      try {
        const keyword_ = keyword.trim();
        const search = JSON.stringify({ _id: keyword_ });
        const url = `/totp/monitor-user?page=${page + 1}&pageSize=${pageSize}&search=${search}&sort={"first_init":-1}`;
        const response = await axios.get(url);
        const { docs, docCount } = response.data;
        setRowCount(docCount);
        const newRows = docs.map((item, index) => ({
          ...item,
          index: page * pageSize + index + 1,
          id: item._id,
          firstInit: item.first_init ? formatDate(item.first_init * 1000) : null,
          lastReset: item.last_reset ? formatDate(item.last_reset * 1000) : null,
          lastVerify: item.last_verify ? formatDate(item.last_verify.timestamp * 1000) : null,
          lastVerifyResult: item.last_verify ? (item.last_verify.valid === true ? 'Success' : 'Fail') : null
        }));
        setRows(newRows);
      } catch (error) {
        setRows([]);
        if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
        else enqueueSnackbar(JSON.stringify(error), ERR_TOP_CENTER);
      }

      if (!active) return;

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, keyword, nonce]);

  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        loading={loading}
        disableSelectionOnClick
      />
    </div>
  );
}
