import moment from 'moment-timezone';

// export const moneyFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'VND'
// });

// export function numberWithCommas(num) {
//   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// }

// export function round(num, base = 4) {
//   return Math.round((num + Number.EPSILON) * 10 ** base) / 10 ** base;
// }

// export function getCurrentDateTimeStr() {
//   return moment.tz(process.env.TIME_ZONE).format();
// }

export function formatDate(timestamp) {
  const dateTimeStr = moment(timestamp).tz(process.env.REACT_APP_TIME_ZONE).format();
  const date = dateTimeStr.slice(0, 10);
  let time = dateTimeStr.slice(11, 19);
  let zone = dateTimeStr.slice(19, 25);
  const [h, m, s] = time.split(':');
  time = `${h}:${m}:${s}`;
  zone = zone.replace(':', 'h').replace('+', '');
  const dateTimeZone = `${date} ${time}`;
  // return { date, time, zone, dateTimeZone };
  return dateTimeZone;
}
