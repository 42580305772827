// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconList,
  IconListSearch,
  IconCertificate,
  IconZoomExclamation,
  IconZoomQuestion,
  IconUser
} from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const totp = {
  id: 'totp',
  title: 'TOTP Administration',
  type: 'group',
  children: [
    {
      id: 'monitor-user',
      title: 'Monitor User',
      type: 'item',
      url: '/totp/monitor-user',
      icon: IconUser,
      breadcrumbs: false
    }
  ]
};

export default totp;
