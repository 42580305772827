import { Box } from '@mui/material';
import { useState } from 'react';
import { axs } from '../../../../config/axios';
// import LogTable from '../LogTable';
import SearchForm from '../SearchForm';
import RequestLogTable from './RequestLogTable';

export default function RequestLog() {
  const [params, setParams] = useState(null);
  let query = '';
  if (params) {
    query = `&extend=${JSON.stringify(params)}`;
  }

  return (
    <Box>
      <SearchForm {...{ method: 'ALL', setParams }} />
      <Box mt={2} />
      <RequestLogTable axios={axs.log} path="/cert/log/request-log" query={query} contentColumnWidth={2000} />
    </Box>
  );
}
