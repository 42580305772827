import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axs } from '../../../../../config/axios';
import { ERR_TOP_CENTER, SUCCESS_TOP_CENTER } from '../../../../../utils/snackbar-utils';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { formatDate } from '../../../../../utils/formatter';
import SendIcon from '@mui/icons-material/Send';

const axios = axs.log;

export default function UserDetail() {
  const { _id } = useParams();
  const [info, setInfo] = useState(null);
  const [ccList, setCCList] = useState([]);
  const [totp, setTotp] = useState('');
  const [recipient, setRecipient] = useState('');
  const [nonce, setNonce] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  async function fetchUserMonitorDetails() {
    try {
      const response = await axios.get(`/totp/monitor-user/${_id}`);
      const history = response.data.history;
      const activities = history.map((item) => {
        if (item.init) return { actionType: 'Init', timestamp: item.init };
        if (item.reset) return { actionType: 'Reset', timestamp: item.reset };
        if (item.verify)
          return {
            actionType: 'Verify',
            timestamp: item.verify.timestamp,
            result: item.verify.valid.toString()
          };
        if (item.totp_send_mail)
          return {
            actionType: 'SendTotpViaEmail',
            timestamp: item.totp_send_mail.timestamp,
            result: item.totp_send_mail.valid.valid ? 'Success' : 'Fail'
          };
        return { actionType: 'unknown' };
      });
      setInfo({ ...response.data, activities });
    } catch (error) {
      setInfo(false);
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    }
  }

  useEffect(() => {
    fetchUserMonitorDetails();
  }, [nonce]);

  async function fetchUserProfile() {
    try {
      const response = await axios.get(`/totp/users/${_id}`);
      setCCList(response.data.backup_emails || []);
      // console.log('🚧 --> fetchUserProfile --> response:', response.data);
    } catch (error) {
      // setCCList(false);
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    }
  }

  useEffect(() => {
    fetchUserProfile();
  }, []);

  async function hdVerify() {
    try {
      const response = await axs.totp.post('/totp/verify', { user_id: _id, code: totp });
      if (response.data.valid) enqueueSnackbar('Correct!', SUCCESS_TOP_CENTER);
      if (!response.data.valid) enqueueSnackbar('Wrong!', ERR_TOP_CENTER);
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  async function hdSendTOTP() {
    try {
      const response = await axs.totp.post('/totp/totp-send-to-email', { user_id: _id });
      if (response.data.data.valid) enqueueSnackbar('Success!', SUCCESS_TOP_CENTER);
      if (!response.data.data.valid) enqueueSnackbar('Fail!', ERR_TOP_CENTER);
      setNonce(Date.now());
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  async function hdUnsetUser() {
    try {
      const response = await axs.totp.post('/totp/reset', { user_id: _id });
      if (response.data.success) enqueueSnackbar('Success!', SUCCESS_TOP_CENTER);
      if (!response.data.success) enqueueSnackbar('Fail!', ERR_TOP_CENTER);
      setNonce(Date.now());
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  async function hdReInit() {
    try {
      const response = await axs.totp.post('/totp/init', { user_id: _id });
      enqueueSnackbar('Success!', SUCCESS_TOP_CENTER);
      setNonce(Date.now());
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  async function hdAddRecipient() {
    try {
      const response = await axs.totp.post('/totp/update-backup-emails', { user_id: _id, backup_emails: [...ccList, recipient] });
      fetchUserProfile();
      enqueueSnackbar('Add successfully!', SUCCESS_TOP_CENTER);
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  async function hdDeleteChip(chipToDelete) {
    try {
      // const remain = ccList.filter((item) => item !== chipToDelete);
      // setCCList((cclist) => cclist.filter((item) => item !== chipToDelete));
      const remain = ccList.filter((item) => item !== chipToDelete);
      const response = await axs.totp.post('/totp/update-backup-emails', { user_id: _id, backup_emails: remain });
      fetchUserProfile();
      enqueueSnackbar('Update successfully!', SUCCESS_TOP_CENTER);
    } catch (error) {
      enqueueSnackbar('Something went wrong!' + error, ERR_TOP_CENTER);
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Box py={1}>
            <Typography variant="h4" gutterBottom>
              Config CC TOTP email
            </Typography>
            <TextField label="recipient" size="small" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
            <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={(e) => hdAddRecipient()}>
              Add Recipient
            </Button>

            <Box mt={1}>
              {'CC recipients: '}
              {ccList.map((item) => (
                <Chip label={item} onDelete={() => hdDeleteChip(item)} sx={{ ml: 1 }} />
              ))}
              {ccList.length === 0 && '[ ]'}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box py={1}>
            <Typography variant="h4" gutterBottom>
              Send TOTP via mail
            </Typography>
            <Button variant="contained" color="primary" onClick={(e) => hdSendTOTP()} endIcon={<SendIcon />}>
              Send
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box py={1}>
            <Typography variant="h4" gutterBottom>
              Verify TOTP
            </Typography>
            <TextField label="totp" size="small" value={totp} onChange={(e) => setTotp(e.target.value)} inputProps={{ size: 10 }} />
            <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={(e) => hdVerify()}>
              Verify
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box>
            {info?.active && (
              <Box py={1}>
                <Typography variant="h4" gutterBottom>
                  Reset user
                </Typography>
                <Button variant="contained" color="error" onClick={(e) => hdUnsetUser()}>
                  Reset
                </Button>
              </Box>
            )}

            {!info?.active && (
              <Box py={1}>
                <Typography variant="h4" gutterBottom>
                  ReInit
                </Typography>
                <Button variant="contained" color="primary" onClick={(e) => hdReInit()}>
                  Init
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box py={2} />

      {info === null && <CircularProgress />}
      {info === false && <Typography>Load data fail!</Typography>}
      {info && (
        <Box>
          <Typography variant="h4" gutterBottom>
            Activities of {_id}
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.activities.map((item, index) => (
                  <TableRow key={item.timestamp}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.actionType}</TableCell>
                    <TableCell>{formatDate(item.timestamp * 1000)}</TableCell>
                    <TableCell>{item.result}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
}
