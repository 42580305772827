import { Box } from '@mui/material';
import { useState } from 'react';
import { axs } from '../../../../config/axios';
import LogTable from '../LogTable';
import SearchForm from '../SearchForm';

export default function ErrorLog() {
  const [params, setParams] = useState();
  let query = '';
  if (params) {
    query = `&extend=${JSON.stringify(params)}`;
  }

  return (
    <Box>
      <SearchForm {...{ setParams }} />
      <Box mt={2} />
      <LogTable axios={axs.log} path="/cert/log/error-log" query={query} />
    </Box>
  );
}
