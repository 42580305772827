import axios from 'axios';

export const axs = (() => {
  let token = localStorage.getItem('token');
  if (token) {
    token = JSON.parse(token);
    return {
      log: axios.create({
        baseURL: process.env.REACT_APP_LOG_SERVER_URL,
        headers: { Authorization: `Bearer ${token.log}` }
      }),
      cert: axios.create({
        baseURL: process.env.REACT_APP_CERT_MANAGER_URL,
        headers: { Authorization: `Bearer ${token.cert}` }
      }),
      totp: axios.create({
        baseURL: process.env.REACT_APP_TOTP_SERVER_URL,
        headers: { Authorization: `Bearer ${token.totp}` }
      })
    };
  }
  return {
    log: axios.create({
      baseURL: process.env.REACT_APP_LOG_SERVER_URL
    }),
    cert: axios.create({
      baseURL: process.env.REACT_APP_CERT_MANAGER_URL
    }),
    totp: axios.create({
      baseURL: process.env.REACT_APP_TOTP_SERVER_URL
    })
  };
})();
