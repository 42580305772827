// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconList,
  IconListSearch,
  IconCertificate,
  IconZoomExclamation,
  IconZoomQuestion
} from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const certManager = {
  id: 'cert-manager',
  title: 'Certificate Management',
  type: 'group',
  children: [
    {
      id: 'certificates',
      title: 'Certificates',
      type: 'item',
      url: '/cert-manager/certificates',
      icon: IconCertificate,
      breadcrumbs: false
    },
    {
      id: 'request-log',
      title: 'Request log',
      type: 'item',
      url: '/cert-manager/log/request-log',
      icon: IconListSearch,
      breadcrumbs: false
    },
    {
      id: 'error-log',
      title: 'Error log',
      type: 'item',
      url: '/cert-manager/log/error-log',
      icon: IconZoomExclamation,
      breadcrumbs: false
    },
    {
      id: 'console-log',
      title: 'Console Log',
      type: 'item',
      url: '/cert-manager/log/console-log',
      icon: IconZoomQuestion,
      breadcrumbs: false
    }
  ]
};

export default certManager;
