import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { axs } from '../../../../config/axios';
import { ERR_TOP_CENTER, SUCCESS_TOP_CENTER } from '../../../../utils/snackbar-utils';

const axios = axs.totp;

export default function InitTOTPForm({ open, handleClose, setNonce }) {
  const [userId, setUserId] = useState('');
  const [creating, setCreating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function hdInitTOTP() {
    try {
      setCreating(true);
      const response = await axios.post('/totp/init', { user_id: userId });
      setNonce(Date.now());
      enqueueSnackbar('Create successfully!', SUCCESS_TOP_CENTER);
    } catch (error) {
      console.log(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    } finally {
      setCreating(false);
      handleClose();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: '1rem' }}>Init TOTP User</DialogTitle>
      <DialogContent>
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="userId"
                label="User Id (email)"
                value={userId}
                onChange={(e) => setUserId(e.target.value.trim())}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => hdInitTOTP()} disabled={creating}>
            {creating ? <CircularProgress size="1rem" /> : 'Init'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
