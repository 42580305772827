import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function SimpleTable({ object }) {
  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableBody>
          {Object.entries(object).map((entry, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: '25%' }}>{entry[0]}</TableCell>
              <TableCell>{entry[1]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
