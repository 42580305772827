import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ERR_TOP_CENTER, SUCCESS_TOP_CENTER } from '../../../utils/snackbar-utils';
import { useSnackbar } from 'notistack';
// import axios from 'axios';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import SimpleTable from '../../../ui-component/SimpleTable';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ConfirmDialog from './ConfirmDialog';
import RenewCertForm from './RenewCertForm';
import { axs } from '../../../config/axios';

const axios = axs.cert;

export default function CertificateDetail() {
  const { teacherId } = useParams();
  const [info, setInfo] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [nonce, setNonce] = useState(0);

  function hdClose() {
    setOpenConfirm(false);
  }

  async function hdRevoke() {
    try {
      setLoading(true);
      const response = await axios.post('/cert/revoke', { teacherId });
      enqueueSnackbar('Revoked!', SUCCESS_TOP_CENTER);
      // navigate('/cert-manager/certificates');
    } catch (error) {
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    } finally {
      setLoading(false);
    }
  }

  async function hdDelete() {
    try {
      setLoading(true);

      const response = await axios.post('/cert/delete', { teacherId });
      enqueueSnackbar('Deleted!', SUCCESS_TOP_CENTER);
      navigate('/cert-manager/certificates');
    } catch (error) {
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCertificateInfo() {
    try {
      const response = await axios.get(`/cert/info?teacherId=${teacherId}`);
      setInfo(response.data);
    } catch (error) {
      setInfo(false);
      console.error(error);
      if (error.response) enqueueSnackbar(JSON.stringify(error.response.data), ERR_TOP_CENTER);
    }
  }

  useEffect(() => {
    if (loading !== true) fetchCertificateInfo();
  }, [loading, nonce]);

  return (
    <div>
      {info === null && <CircularProgress />}
      {info === false && <Typography>Fail to load data!</Typography>}
      {info &&
        (() => {
          const {
            certInfo: { subject, issuer, ...rest2 },
            ...rest
          } = info;
          return (
            <Box>
              {loading ? (
                <Box sx={{ textAlign: 'right' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ textAlign: 'right' }}>
                  <Button variant="contained" startIcon={<AutorenewIcon />} onClick={() => setOpenRenew(true)}>
                    Renew
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    sx={{ ml: 2 }}
                    startIcon={<ReplayIcon />}
                    onClick={() => hdRevoke()}
                    disabled={info.status === 'revoked'}
                  >
                    Revoke
                  </Button>
                  <Button variant="contained" color="error" startIcon={<DeleteIcon />} sx={{ ml: 2 }} onClick={() => setOpenConfirm(true)}>
                    Delete
                  </Button>
                  {openRenew && <RenewCertForm info={info} handleClose={() => setOpenRenew(false)} open={openRenew} setNonce={setNonce} />}
                  {openConfirm && <ConfirmDialog open={openConfirm} hdCancel={() => hdClose()} hdProceed={() => hdDelete()} />}
                </Box>
              )}

              <Box>
                <Typography variant="h4" sx={{ my: 1 }}>
                  Subject
                </Typography>
                <SimpleTable object={{ ...subject, names: subject.names.join(', ') }} />
              </Box>

              <Box>
                <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
                  Issuer
                </Typography>
                <SimpleTable object={{ ...issuer, names: issuer.names.join(', ') }} />
              </Box>

              <Box>
                <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
                  Other info
                </Typography>
                <SimpleTable object={Object.assign(rest, rest2)} />
              </Box>
            </Box>
          );
        })()}
    </div>
  );
}
