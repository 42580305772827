import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import { getToken } from '../utils/mng-token';
import CertificateDetail from '../views/pages/CertificateDetail';
import RequestLog from '../views/pages/Log/RequestLog';
import ErrorLog from '../views/pages/Log/ErrorLog';
import Log from '../views/pages/Log/Log';
import MonitorUser from '../views/pages/Totp/MonitorUser';
import UserDetail from '../views/pages/Totp/MonitorUser/UserDetail';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CertificateList = Loadable(lazy(() => import('views/pages/CertificateList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Redirector />
    },
    {
      path: '/cert-manager/certificates',
      element: <CertificateList />
    },
    {
      path: '/cert-manager/certificates/:teacherId',
      element: <CertificateDetail />
    },
    {
      path: '/cert-manager/log/request-log',
      element: <RequestLog />
    },
    {
      path: '/cert-manager/log/error-log',
      element: <ErrorLog />
    },
    {
      path: '/cert-manager/log/console-log',
      element: <Log />
    },
    {
      path: '/totp/monitor-user',
      element: <MonitorUser />
    },
    {
      path: '/totp/monitor-user/:_id',
      element: <UserDetail />
    }
  ]
};

function Redirector() {
  const token = getToken();
  if (!token) return <Navigate replace to="/login" />;
  return <Navigate replace to="/cert-manager/certificates" />;
}

export default MainRoutes;
