import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import UserStatusTable from './UserStatusTable';
import AddIcon from '@mui/icons-material/Add';
import InitTOTPForm from './InitTOTPForm';

export default function MonitorUser() {
  const [textInput, setTextInput] = useState('');
  const [keyword, setKeyword] = useState('');
  const [open, setOpen] = useState(false);
  const [nonce, setNonce] = useState(0);

  function onSearch() {
    setKeyword(textInput);
  }
  function hdKeyUp(e) {
    if (e.keyCode === 13) onSearch();
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <TextField
            variant="standard"
            label="Id"
            inputProps={{ style: { width: 250 } }}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyUp={hdKeyUp}
            autoFocus
          />

          <Button variant="contained" color="primary" sx={{ ml: 2 }} startIcon={<SearchIcon />} onClick={() => onSearch()}>
            Search
          </Button>

          {textInput !== '' && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: 2 }}
              startIcon={<ClearIcon />}
              onClick={() => {
                setTextInput('');
                setKeyword('');
              }}
            >
              Clear search
            </Button>
          )}
        </Box>

        <Box>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)} endIcon={<AddIcon />}>
            Add
          </Button>
          <InitTOTPForm {...{ open, handleClose, setNonce }} />
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <UserStatusTable nonce={nonce} keyword={keyword} />
      </Box>
    </Box>
  );
}
